import React, { FunctionComponent } from "react";

export type StatisticsItemProps = {
    value: number | string;
    text: string;
}

export const StatisticsItem: FunctionComponent<StatisticsItemProps> = ({ value, text }) => (
    <div className="statistic_item">
        <div className="statistic_item__value">{value}</div>
        <div className="statistic_item__text">{text}</div>
    </div>
);
