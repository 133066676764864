import { getIsCzechLanguageActive } from "./utils"

type Translation = {
    statistcs: string,
    title: string,
    switchDarkTheme: string,
    switchLightTheme: string,
    home: string,
    guest: string,
    games: string,
    clicks: string,
    swiped: string,
    loading: string,
    googlePlay: string,
    completed: string,
}

const czech: Translation = {
    statistcs: "Statistiky",
    title: "Závody Prstů: Zběsilé ukazováčky",
    switchDarkTheme: "Přepnout na tmavý mód",
    switchLightTheme: "Přepnout na světlý mód",
    home: "Domácí",
    guest: "Hosté",
    games: "her",
    clicks: "tapnutí",
    swiped: "naswajpováno",
    loading: "Načítám…",
    googlePlay: "Google Play",
    completed: "úroveň pokořena",
}

const english: Translation = {
    statistcs: "Statistics",
    title: "Finger Race: Forefinger Rampage",
    switchDarkTheme: "Switch to dark theme",
    switchLightTheme: "Switch to light theme",
    home: "Home",
    guest: "Guest",
    games: "games",
    clicks: "taps",
    swiped: "swiped",
    loading: "Loading…",
    googlePlay: "Google Play",
    completed: "level completed",
}

export const translation: Translation = getIsCzechLanguageActive() ? czech : english
