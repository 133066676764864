import React, { useState, useEffect, Fragment } from "react";

import { firebaseRef } from "./firebase";
import {
    millimetresToSwipeLength,
    persistIsDarkThemeActive,
    loadIsDarkThemeActive,
    sumNumberArray,
    getGameClickCount,
    getGameSwipeLengthMm,
    getLightGameScoreSum,
    getDarkGameScoreSum,
    getIsCzechLanguageActive,
    getMaxCompletedLevel,
    numberToOrdinal,
} from "./utils";
import { translation } from "./translation";
import { StatisticsState, Snapshot } from "./types";
import { ScoreItem, StatisticsItem } from "./components";

export const App = () => {
    const [statisticsState, setStatisticsState] = useState<StatisticsState | undefined>(undefined);
    const [isDarkThemeActive, setIsDarkThemeActive] = useState(loadIsDarkThemeActive());

    useEffect(() => {
        firebaseRef.on("value", snapshot => {
            if (snapshot.val() == null) {
                return;
            }
            const snapshotValue = snapshot.val() as Snapshot;
            const games = Object
                .values(snapshotValue.versionCodes)
                .map(versionCodeData => Object.values(versionCodeData.users))
                .flat()
                .map(user => Object.values(user.games))
                .flat();
            const clickCount = sumNumberArray(games.map(game => getGameClickCount(game)));
            const swipeLengthMm = sumNumberArray(games.map(game => getGameSwipeLengthMm(game)));
            const gameCount = games.length;
            const lightScoreSum = sumNumberArray(games.map(game => getLightGameScoreSum(game)));
            const darkScoreSum = sumNumberArray(games.map(game => getDarkGameScoreSum(game)));
            const maxCompletedLevel = getMaxCompletedLevel(games);
            setStatisticsState({ clickCount, swipeLengthMm, gameCount, lightScoreSum, darkScoreSum, maxCompletedLevel });
        });
        document.title = translation.title
    }, []);

    const toggleTheme = () => {
        setIsDarkThemeActive(!isDarkThemeActive);
        persistIsDarkThemeActive(!isDarkThemeActive)
    }

    return (
        <div className={`app ${isDarkThemeActive ? "dark-theme" : "light-theme"} animated-transition fill-window`}>
            <div className="header-buttons">
                <a href="https://play.google.com/store/apps/details?id=com.pouproduction.fingerrace">
                    <button className="button animated-transition no-selection">
                        {translation.googlePlay}
                    </button>
                </a>
                <button
                    className="button animated-transition no-selection"
                    onClick={toggleTheme}
                >
                    {isDarkThemeActive ? translation.switchLightTheme : translation.switchDarkTheme}
                </button>
            </div>
            <h2 className="app__subtitle">
                {translation.statistcs}
            </h2>
            <h1 className="app__title no-selection">
                {translation.title}
            </h1>
            <div className="content">
                {statisticsState == null ? (
                    <p className="no-selection loading_text">
                        {translation.loading}
                    </p>
                ) : (
                    <Fragment>
                        <div className="score_items_wrapper">
                            <ScoreItem
                                label={translation.home}
                                value={statisticsState.lightScoreSum}
                            />
                            <div className="score_delimiter">:</div>
                            <ScoreItem
                                label={translation.guest}
                                value={statisticsState.darkScoreSum}
                            />
                        </div>
                        <div className="statistic_items_list">
                            <StatisticsItem
                                value={statisticsState.gameCount.toLocaleString()}
                                text={translation.games}
                            />
                            <StatisticsItem
                                value={statisticsState.clickCount.toLocaleString()}
                                text={translation.clicks}
                            />
                            <StatisticsItem
                                value={millimetresToSwipeLength(statisticsState.swipeLengthMm, !getIsCzechLanguageActive())}
                                text={translation.swiped}
                            />
                            <StatisticsItem
                                value={numberToOrdinal(statisticsState.maxCompletedLevel, getIsCzechLanguageActive())}
                                text={translation.completed}
                            />
                        </div>
                    </Fragment>
                )}
            </div>
        </div>
    );
};
