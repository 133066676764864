import React, { FunctionComponent } from "react";

export type ScoreItemProps = {
    value: number;
    label: string;
}

export const ScoreItem: FunctionComponent<ScoreItemProps> = ({ value, label }) => (
    <div className="score_item">
        <div className="score_item__label">{label}</div>
        <div className="score_item__value">{value.toLocaleString()}</div>
    </div>
);
