export const firebaseConfig = {
    apiKey: "AIzaSyDVKPyBRLS5BG1NdSD2VT5OrfhRM85QSSE",
    authDomain: "fingerrace-b6db5.firebaseapp.com",
    databaseURL: "https://fingerrace-b6db5.firebaseio.com",
    projectId: "fingerrace-b6db5",
    storageBucket: "fingerrace-b6db5.appspot.com",
    messagingSenderId: "554000631472",
    appId: "1:554000631472:web:f85510e67da32b9ccb1d75",
    measurementId: "G-771HW9ZSG7",
};

export const IS_DARK_THEME_ACTIVE = "isDarkThemeActive";
export const TRUE = "true";
export const MM_IN_KM = 10 * 100 * 1000;
export const MM_IN_MI = 1_609_344;
export const THIN_SPACE = " ";
export const EN_QUAD = " ";
export const HAIR_SPACE = " ";
