import { MM_IN_KM, IS_DARK_THEME_ACTIVE, TRUE, THIN_SPACE, MM_IN_MI, EN_QUAD, HAIR_SPACE } from "./constants";
import { Game } from "./types";

export const millimetresToSwipeLength = (millimetres: number, isEnglishVersionActive: boolean): string => {
    const metricValue = `${formatNumber(millimetres / MM_IN_KM)}${THIN_SPACE}km`;

    if (!isEnglishVersionActive) {
        return metricValue;
    }

    const imperialValue = `${formatNumber(millimetres / MM_IN_MI)}${THIN_SPACE}mi`;

    return `${metricValue}${EN_QUAD}/${EN_QUAD}${imperialValue}`;
}

const formatNumber = (value: number) => value.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })

export const persistIsDarkThemeActive = (isDarkThemeActive: boolean) => {
    localStorage.setItem(IS_DARK_THEME_ACTIVE, isDarkThemeActive ? "true" : "false");
};

export const loadIsDarkThemeActive = (): boolean => {
    return localStorage.getItem(IS_DARK_THEME_ACTIVE) === TRUE;
};

export const sumIfValid = (a: number, b: number) => {
    if (isNaN(b)) {
        console.log("Invalid number");
        return a;
    } else {
        return a + b;
    }
}

export const getLanguage = () => (navigator.languages && navigator.languages.length && navigator.languages[0]) || navigator.language || "en";

export const sumNumberArray = (values: number[]) => values.reduce((a, b) => a + b);

export const getNumberOrZero = (value: number | undefined) => value === undefined ? 0 : isNaN(value) ? 0 : value

export const getGameClickCount = (game: Game): number => getValuesSum(getClickCountValues(game))

export const getGameSwipeLengthMm = (game: Game): number => getValuesSum(getSwipeLengthMmValues(game))

export const getLightGameScoreSum = (game: Game): number => getValuesSum(getLightGameScoreValues(game))

export const getDarkGameScoreSum = (game: Game): number => getValuesSum(getDarkGameScoreValues(game))

export const getValuesSum = (values: number[]) => sumNumberArray(values.map(value => getNumberOrZero(value)));

export const getClickCountValues = (game: Game) => [game.lightClickCount, game.homeClickCount, game.darkClickCount, game.guestClickCount];

export const getSwipeLengthMmValues = (game: Game) => [game.lightSwipeLengthMm, game.homeSwipeLengthMm, game.darkSwipeLengthMm, game.guestSwipeLengthMm];

export const getLightGameScoreValues = (game: Game) => [game.lightScore, game.homeScore];

export const getDarkGameScoreValues = (game: Game) => [game.darkScore, game.guestScore];

export const getIsCzechLanguageActive = () => getLanguage() === "cs-CZ";

export const getMaxCompletedLevel = (gameList: Game[]) => gameList
    .filter((game) => game.completedLevel !== undefined)
    .map((game) => game.completedLevel)
    .sort((a, b) => getNumberOrZero(a) - getNumberOrZero(b))
    .reverse()[0] ?? 0

export const numberToOrdinal = (value: number, isCzechLanguageActive: boolean): string => {
    if (isCzechLanguageActive) {
        return `${value}.`;
    }
    const valueString = value.toString();

    if (value > 3 && value < 21) {
        return `${value}${HAIR_SPACE}th`;
    }

    switch (valueString[valueString.length - 1]) {
        case "1":
            return `${value}st`;
        case "2":
            return `${value}nd`;
        case "3":
            return `${value}rd`;
        default:
            return `${value}th`;
    }
}
